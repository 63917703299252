<template>
  <div class="homePage">
    <!--顶部-->
    <div class="container_top">
      <span class="container_top_text">{{$t("language.Inbox")}}</span>
      <div style="
          margin-left: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 85%;
        ">
        <el-input v-model="input2" class="container_top_input" style="width: 100%" :placeholder="$t('language.search')"
          prefix-icon="Search" />
      </div>
    </div>
    <el-scrollbar>
      <div class="container_main">
        <div v-if="hasEmails&&!loading">
          <!-- 今天的 -->
          <div class="today-mail" style="">
            <span class="today-text" v-if="todayEmails.length > 0">{{$t("language.today")}}</span>
            <div class="mail-infos">
              <router-link :to="{ params: { id: item.id, source: 'receive' } }" @click.prevent="receivingDetails(item)"
                class="menu-link" active-class="active-link" v-for="item in todayEmails" :key="item.id">
                <div class="mail-info-itme">
                  <div class="item-avatar"></div>
                  <div class="item-unread" v-if="item.is_read === 'false'"></div>

                  <div class="item-content">
                    <div class="text">
                      <div class="text_button">
                        <span class="item-content-text1">{{$t("language.Sender")}}：{{ item.sender }}</span>
                        <span class="item-content-text2">{{ item.subject }}</span>
                      </div>
                      <div class="text_button">
                        <span class="item-content-text3" style="float: right">{{ formatDate(item.received_at) }}</span>
                        <el-button type="danger" plain @click.stop="del(item)">{{$t("language.delete")}}</el-button>
                      </div>
                    </div>
                    <!-- <span class="item-content-text3">邮件一部分内容</span> -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <!-- 昨天 -->
          <div class="today-mail" style="">
            <span class="today-text" v-if="yesterdayEmails.length > 0">{{$t("language.yesterday")}}</span>
            <div class="mail-infos">
              <router-link :to="{ params: { id: item.id, source: 'receive' } }" @click.prevent="receivingDetails(item)"
                class="menu-link" active-class="active-link" v-for="item in yesterdayEmails" :key="item.id">
                <div class="mail-info-itme">
                  <div class="item-avatar"></div>
                  <div class="item-unread" v-if="item.is_read === 'false'"></div>
                  <div class="item-content">
                    <div class="text">
                      <div class="text_button">
                        <span class="item-content-text1">{{$t("language.Sender")}}：{{ item.sender }}</span>
                        <span class="item-content-text2">{{ item.subject }}</span>
                      </div>
                      <div class="text_button">
                        <span class="item-content-text3" style="float: right">{{ formatDate(item.received_at) }}</span>
                        <el-button type="danger" plain @click.stop="del(item)">{{$t("language.delete")}}</el-button>
                      </div>
                    </div>
                    <!-- <span class="item-content-text3">邮件一部分内容</span> -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- 更早的 -->
          <div class="today-mail" style="">
            <span class="today-text" v-if="earlierEmails.length > 0">{{$t("language.Earlier")}}</span>
            <div class="mail-infos">
              <router-link :to="{ params: { id: item.id, source: 'receive' } }" @click.prevent="receivingDetails(item)"
                class="menu-link" active-class="active-link" v-for="item in earlierEmails" :key="item.id">
                <div class="mail-info-itme">
                  <div class="item-avatar"></div>
                  <div class="item-unread" v-if="item.is_read === 'false'"></div>
                  <div class="item-content">
                    <div class="text">
                      <div class="text_button">
                        <span class="item-content-text1">{{$t("language.Sender")}}：{{ item.sender }}</span>
                        <span class="item-content-text2">{{ item.subject }}</span>
                      </div>
                      <div class="text_button">
                        <span class="item-content-text3" style="float: right">{{ formatDate(item.received_at) }}</span>
                        <el-button type="danger" plain @click.stop="del(item)">{{$t("language.delete")}}</el-button>
                      </div>
                    </div>
                    <!-- <span class="item-content-text3">邮件一部分内容</span> -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div v-else-if="loading">
      <el-skeleton :rows="5" animated />
    </div>
        <div class="nothing" v-else>
          <img src="../../assets/empty-page-bg/4.png" style="width: 500px;">
          <span class="nothing-text">{{$t("language.Noneinbox")}}</span>
        </div>
      </div>

    </el-scrollbar>
    <el-pagination v-if="total > 0" :current-page.sync="currentPage" :page-size="pageSize"
      layout="total, prev, pager, next, jumper" :total="total" @current-change="handlePageChange" :pager-count="10"
      hide-on-single-page>
    </el-pagination>
  </div>

</template>


<script setup>
import { ref, onMounted, computed,watch } from "vue";
import { receiveEmailListApi, receiveEmailDeleteApi, emailReadApi,receiveEmailSearchApi } from '@/api/index'
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

const router = useRouter();
const loading = ref(true)
const recipientList = ref([])
// 分页相关的状态
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(0)

// 搜索相关
const input2 = ref(""); // 搜索输入框的内容

// 监听 input2 的变化来进行搜索
watch(input2, (newValue) => {
  if (newValue) {
    searchEmails(newValue);
  } else {
    fetchEmails(); // 如果搜索框为空，则重新加载邮件列表
  }
});

const searchEmails = (query) => {
  const params = {
    page: currentPage.value,
    pageSize: pageSize.value,
    query: query, // 搜索关键词
  };
  
  receiveEmailSearchApi(params).then(res => {
    console.log(params);
    
    if (res.code === 200) {
      recipientList.value = res.data.emails||[];
      console.log("TAG 搜索结果: ", res);
      // ElMessage.success('搜索邮件成功');
      total.value = res.data.total || 0;
      classifyEmails(); // 更新分类后的邮件列表
    }else {
      recipientList.value = []; // 如果code不是200，确保recipientList是空数组
      total.value = 0;
      classifyEmails();
    }
  }).catch(err => {
    console.log("TAG 搜索邮件失败: ", err);
    ElMessage.error('搜索邮件失败');
    recipientList.value = []; // 确保出错时 recipientList 是空数组
    total.value = 0;
    classifyEmails();
  });
};




const todayEmails = ref([])
const yesterdayEmails = ref([])
const earlierEmails = ref([])
// 是否有收件
const hasEmails = computed(() => {
  return todayEmails.value.length > 0 || yesterdayEmails.value.length > 0 || earlierEmails.value.length > 0
})

onMounted(() => {
  fetchEmails()
})

const fetchEmails = () => {
  const params = {
    page: currentPage.value,
    pageSize: pageSize.value,
  }
  receiveEmailListApi(params).then(res => {
    if (res.code === 200) {
      recipientList.value = res.data.emails
      console.log("TAG 获取接收邮件列表 ", recipientList.value);
      total.value = res.data.total // 设置总邮件数

      classifyEmails()
    }
  }).catch(err => {
    console.log("TAG 获取收件箱列表失败 ", err);

    ElMessage.error('获取收件箱列表失败')
  }).finally(() => {
    loading.value = false
  })
}

const classifyEmails = () => {
  todayEmails.value = []
  yesterdayEmails.value = []
  earlierEmails.value = []

  if (!recipientList.value || recipientList.value.length === 0) {
    return;
  }
  const today = dayjs()
  const yesterday = dayjs().subtract(1, 'day')
  recipientList.value.forEach(emails => {
    const emailDate = dayjs(emails.received_at)

    if (emailDate.isSame(today, 'day')) {
      todayEmails.value.push(emails)
    } else if (emailDate.isSame(yesterday, 'day')) {
      yesterdayEmails.value.push(emails)
    } else {
      earlierEmails.value.push(emails)
    }
  })

  // // 更新总数
  // total.value = recipientList.value.length
   console.log("Today Emails:", todayEmails.value);
  console.log("Yesterday Emails:", yesterdayEmails.value);
  console.log("Earlier Emails:", earlierEmails.value);

}
// 时间处理
const formatDate = (dateString) => {
  const date = dayjs(dateString)
  const now = dayjs()

  if (date.isSame(now, 'day')) {
    return date.format('HH:mm')
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return `昨天 ${date.format('HH:mm')}`
  } else {
    return date.format('YYYY/MM/DD')
  }
}

const receivingDetails = (item) => {
  emailReadApi(item.id).then(res => {
    if (res.code === 200) {
      item.is_read = 'true'
    }
  })

  const Details = {
    source: 'receive',
    id: item.id,
  }

  router.push({
    name: 'mailDetails',
    query: JSON.parse(JSON.stringify(Details))
  })
}

// 删除邮件
const del = (item) => {
  receiveEmailDeleteApi(item.id).then(res => {
    if (res.code === 200) {
      recipientList.value = recipientList.value.filter((i) => i.id !== item.id)
      fetchEmails()
    }
  })
}

// 处理分页改变
const handlePageChange = (page) => {
  currentPage.value = page
  if (input2.value) {
    searchEmails(input2.value); // 如果有搜索内容，则基于搜索进行分页
  } else {
    fetchEmails(); // 如果没有搜索内容，恢复正常的分页处理
  }
}


</script>


<style scoped lang="less">
.container_top {
  height: 50px;
  background: #ffffff;
  // margin: 15px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: baseline;
}


.container_top_input {
  --el-border-color: transparent;
  --el-input-hover-border-color: transparent;
  --el-input-focus-border-color: transparent;
  --el-border-radius-base: 6px;
  height: 32px;
}

:deep(.el-input__wrapper) {
  background-color: #e4edf55b;
  background: linear-gradient(to right, #e4edf55b, #fff);
}


.today-mail {
  // padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.today-text {
  color: #7686b4;
  font-weight: bold;
  margin: 10px;
}

.mail-info-itme {
  height: 50px;
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-radius: 10px;
}

.menu-link {
  text-decoration: none;
}

.active-link {
  text-decoration: none;
}
.nothing {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: black;
}

.item-unread {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ff0000;
  //   padding: 2px;
  // margin-left: -5px;
}

.item-content {
  // height: calc(100%-15px);
  height: 100%;
  width: 100%;
  // background-color: #fd6a6a;
  padding: 0 10px;

  display: flex;
  flex-direction: column;
}

.item-content-text1 {
  color: #22222b;
}

.item-content-text2 {
  color: #a1abc2;
  font-size: 12px;
  margin: 4px 0;
}

.item-content-text3 {
  color: #a1abc2;
  font-size: 12px;
  margin-top: 5px;
}

.previous-mail {
  // padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.text {
  display: flex;
  justify-content: space-between;
}

.text_button {
  display: flex;
  flex-direction: column;
}

.item-content-text3 {
  text-align: center;
}

.el-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  /* 确保它在其他内容之上 */
  // background-color: white; /* 背景颜色，可以根据需求调整 */
  // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* 阴影效果，可选 */
  display: flex;
  justify-content: center;
}
</style>