import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

// 用Vuex.store对象来记录token
const store = createStore({
  state: {
    // 存储token
    token: "",
    username: "",  // 存储用户名/邮箱账号
    activeMenuIndex: '/homePage',
    isLoggedIn: false , // 登录状态
    role: '' // 用户角色
  },
  getters: {
    // 获取token
    getToken(state) {
      return state.token || localStorage.getItem("token") || ""
    },
    getActiveMenuIndex(state) {
      return state.activeMenuIndex
    },
    getUsername(state) {
      return state.username;
    },
    getIsLoggedIn(state) {
      return state.isLoggedIn;
    },
    getRole: (state) => state.role 
  },
  mutations: {
    // 设置token
    setToken(state, token) {
      state.token = token
    },
    delToken(state) {
      state.token = "";
      state.isLoggedIn = false;
    },
    updateActiveMenuIndex(state, index) {
      state.activeMenuIndex = index
    },
    setUsername(state, username) {
      state.username = username;
    },
    setIsLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    setRole(state, role) { 
      state.role = role;
    }
  },
  actions: {
    setActiveMenuIndex({ commit }, index) {
      commit('updateActiveMenuIndex', index)
    }
  },
  plugins: [createPersistedState()]  // 添加这个行来实现持久化

})
export default store;