<template>
  <div class="makeMail" v-loading="apiloading" element-loading-text="Loading..." :element-loading-spinner="loadinSvg"
    element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(122, 122, 122, 0.8)">
    <span class="first-tit">{{ $t("language.WriteEmail") }}</span>
    <div class="addressee">
      <span class="second-tit">{{ $t("language.recipient") }}：</span>
      <div style="width: 90%;">
        <el-input class="mail-input" type="email" v-model="recipient" style="width: 100%"
          :placeholder="$t('language.Pleasefill')" />
      </div>
    </div>
    <div class="addressee">
      <span class="second-tit">{{ $t("language.theme") }}：</span>
      <div style="">
        <el-input class="mail-input" type="text" v-model="subject" style="width: 100%"
          :placeholder="$t('language.emailtitle')" />
      </div>
    </div>
    <!-- <span class="second-tit">主题：<span>关于网易云音乐的年度企划问题</span></span> -->
    <div class="upload-enclosure">
      <span class="first-tit" style="margin-right: 6px">{{ $t("language.Add") }}</span>


      <el-upload v-model:file-list="fileList" class="upload-demo" action="/user/upload/upload_appendix" multiple
        :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="5"
        :on-exceed="handleExceed" :http-request="customUpload" :show-file-list="false">

        <el-icon style="cursor: pointer;" color="#cad0d8" size="20px">
          <UploadFilled />
        </el-icon>
      </el-upload>
    </div>
    <!-- 循环显示上传的文件 -->
    <div style="display: flex;">
      <div v-for="(file, index) in fileList" :key="file.uid" class="upload-file">
        <div class="file-info">
          <span
            style=" color: #a1abc2; font-size: 14px;width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
            {{ file.name || file.fileName }}</span>
          <span style="color: #a1abc2; font-size: 13px" v-if="file.size">{{ (file.size / 1024).toFixed(2) }} KB</span>
        </div>
        <el-icon class="del" color="#cad0d8" size="25px" @click.stop="beforeRemove(file, fileList)">
          <DeleteFilled />
        </el-icon>
      </div>
    </div>

    <div class="rich-text" style="border: 1px solid #ccc">
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
      <Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode"
        @onCreated="handleCreated" @onChange="handleChanged" v-loading="uploadLoding" />
    </div>

    <div class="main-btn">
      <el-button class="btn-item" @click="clickCancel">
        <el-icon color="#a1abc2" size="17px">
          <CloseBold />
        </el-icon>
        <span style="color: #a1abc2; font-weight: bold">{{ $t("language.Cancel") }}</span>
      </el-button>
      <el-button v-if="!isEditMode" class="btn-item" @click="clickSaveDraft">
        <el-icon color="#a1abc2" size="17px">
          <MessageBox />
        </el-icon>
        <span style="color: #a1abc2; font-weight: bold">{{ $t("language.SaveDraft") }}</span>
      </el-button>

      <!-- 修改草稿，必须从草稿箱点击列表的内容才会在此页面显示修改草稿的按钮 -->
      <el-button v-if="isEditMode" class="btn-item2" @click="clickModifyTheDraft">
        <!-- <el-icon ><Promotion /></el-icon> -->
        <el-icon color="#a1abc2" size="17px">
          <MessageBox />
        </el-icon>
        <span style="color: #a1abc2; font-weight: bold">{{ $t("language.Modifythedraft") }}</span>
      </el-button>

      <el-button class="mail-btn" @click="clickSend">
        <el-icon color="#fff" size="17px">
          <Promotion />
        </el-icon>
        <span style="color: #fff; font-weight: bold">{{ $t("language.send") }}</span>
      </el-button>
    </div>
  </div>


</template>
<script setup>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { saveDraftApi, updateDraftApi, sendMailApi, getDraftDetailApi } from "@/api/index";
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'; // 引入 useStore 以访问 Vuex
import { ElMessage, ElMessageBox } from 'element-plus'
import { uploadAppendix, uploadImageApi } from '@/utils/request'
import MD5 from 'crypto-js/md5';
import CryptoJS from 'crypto-js'; // 需要明确导入CryptoJS
import env from "../../utils/config.js"
import { toolbarConfigJs } from "./toolbarConfig";
import { defaultHtml, loadinSvg } from "./defaultHtml"
import { receiveEmailDetailApi } from '@/api/index';
import { useI18n } from 'vue-i18n'
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const convertToHtml = (text) => {
  return text.replace(/\n/g, '<br>');
}
const apiloading = ref(false);
// 是否为编辑模式（修改草稿）
const isEditMode = ref(false);
onMounted(() => {
  if (editorRef.value) {
    editorRef.value.setHtml(valueHtml.value || ''); // 确保 editorRef 已经初始化
  }
  // 从路由的 query 参数中获取草稿内容
  const query = route.query;
  // if (query.id) {
  //   isEditMode.value = true; // 设置为编辑模式
  if (query.id) {
    // apiloading.value = true;
    getDraftDetailApi(query.id).then(res => {
      console.log("TAG 草稿详情", res);
      if (res.code == 200) {
        valueHtml.value = res.data.body;
        recipient.value = query.recipients;
        subject.value = res.data.subject;
        const file = JSON.parse(res.data.attachments)
        console.log(file);

        fileList.value = file
      }
    })
  }
  //     apiloading.value = false;
  //   })

  // draftId.value = query.id;
  // subject.value = query.subject || '';

  // const htmlContent = convertToHtml(query.body || '');

  // recipient.value = query.recipients || '';
  //在设置 HTML 内容之前确保编辑器已准备就绪
  // if (editorRef.value) {
  //   editorRef.value.setHtml(htmlContent); // 在编辑器中设置 HTML 内容
  // }
  // valueHtml.value = htmlContent;
  // } else {
  //   isEditMode.value = false; // 新邮件模式
  // }

  // 接收params传参
  if (route.params && route.params.id) {
    apiloading.value = true;
    const { id } = route.params;
    receiveEmailDetailApi(id).then(res => {
      console.log("TAG 接收邮件详情", res);
      if (res.code == 200) {
        valueHtml.value = defaultHtml({ body: res.data.body, sender: res.data.sender, subject: res.data.sender, receipt_at: res.data.received_at });
        recipient.value = res.data.sender;
        subject.value = `${t('language.mailValue.reply')}: ` + res.data.subject;
      }
    }).finally(() => {
      apiloading.value = false;
    })
  }

  console.log("TAG sendto", route.params.sendto);

  if (route.params.sendto) {
    recipient.value = route.params.sendto;

  }
  if (route.params.sendname) {
    recipient.value = route.params.sendname;

  }
});

// 收件人邮箱
const recipient = ref("");
// 邮件主题
const subject = ref("");
// 草稿id
const draftId = ref("");

// 上传附件

const fileList = ref([]); // 文件列表

// 自定义上传函数
const customUpload = async (options) => {
  const { file, onProgress, onSuccess, onError } = options;

  // 创建 FormData 对象
  const formData = new FormData();
  formData.append('file', file);

  // 使用 FileReader 读取文件并计算 MD5
  const reader = new FileReader();
  reader.onload = async (e) => {
    // 获取文件的 ArrayBuffer
    const arrayBuffer = e.target.result;
    // 将 ArrayBuffer 转换为 WordArray，以便 CryptoJS 计算 MD5
    const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
    const md5Hash = MD5(wordArray).toString(); // 计算 MD5 哈希值

    try {
      // 调用上传接口，并传入文件和计算的 MD5
      const response = await uploadAppendix(file, md5Hash);

      // 检查上传结果并调用 onSuccess 或 onError
      console.log('Upload Response:', response); // 调试输出完整的响应对象
      // 检查上传结果并调用 onSuccess 或 onError
      if (response.data.code == 200) {
        file.md5 = md5Hash; // 将 MD5 值添加到 file 对象中
        file.responseData = response.data; // 将响应数据添加到 file 对象中
        onSuccess({ url: response.data }); // 上传成功，调用成功回调

        ElMessage.success('文件上传成功');
      } else {
        throw new Error(response.data.msg || '上传失败'); // 上传失败，抛出错误
      }
    } catch (error) {
      onError(error); // 调用错误回调
      ElMessage.error('文件上传失败，请重试');
    }
  };

  // 读取文件为 ArrayBuffer 以进行 MD5 计算
  reader.readAsArrayBuffer(file);
};

const handleRemove = (file, uploadFiles) => {
  console.log(file, uploadFiles)
}

const handlePreview = (uploadFile) => {
  console.log(uploadFile)
}

const handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `限制为 5, 您本次选择了 ${files.length} 个文件，已上传${uploadFiles.length}个文件，总计  ${files.length + uploadFiles.length
    } 个文件`
  )
}

// 删除附件
const beforeRemove = (uploadFile, uploadFiles) => {
  return ElMessageBox.confirm(
    `确定要删除 ${uploadFile.name ? uploadFile.name : uploadFile.fileName} ?`
  ).then(res => {
    if (uploadFile.name) {
      fileList.value = fileList.value.filter(item => item.name !== uploadFile.name);
    } else {
      fileList.value = fileList.value.filter(item => item.fileName !== uploadFile.fileName);
    }
  }).catch(err => { })
}

// 取消按钮
const clickCancel = () => {
  console.log("取消按钮被点击了");
  // 使用 Vue Router 跳转到 homePage 页面
  router.push({ path: '/homePage' })
};





// 保存草稿按钮
const clickSaveDraft = () => {
  console.log("存草稿按钮被点击了");
  // 创建一个临时的 DOM 元素来解析 HTML
  // 提取纯文本内容
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = valueHtml.value;
  const plainText = tempDiv.innerText || tempDiv.textContent || '';
  if (!recipient.value.trim() && !subject.value.trim() && !plainText.trim()) {
    ElMessage({
      message: '请至少填写收件人、主题或正文中的一项内容再保存草稿',
      type: 'warning',
      duration: 2000,
    })
    return;
  }
  const attachmentsDoc = fileList.value.map(file => file.raw.responseData.data).join(',');
  console.log("fileList:", fileList.value);
  console.log("TAG 附件", attachmentsDoc);


  saveDraftApi({ recipients: recipient.value, subject: subject.value, body: plainText, attachments: attachmentsDoc }).then(res => {
    // console.log("TAG 草稿被保存了",res);
    if (res.code === 200) {
      console.log("草稿保存成功", res);
      ElMessage({
        message: '草稿保存成功',
        type: 'success',
        duration: 2000,
      });
      // 使用 Vue Router 跳转到 darfts 页面
      router.push({ path: '/draft' })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      message: '草稿保存失败，请稍后重试',
      type: 'error',
      duration: 2000,
    })

  })


};
// 修改草稿按钮
const clickModifyTheDraft = () => {
  console.log("修改草稿按钮被点击了");
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = valueHtml.value;
  const plainText = tempDiv.innerText || tempDiv.textContent || '';
  updateDraftApi({ id: draftId.value, recipients: recipient.value, subject: subject.value, body: plainText }).then(res => {
    if (res.code === 200) {
      ElMessage({
        message: '草稿修改成功',
        type: 'success',
        duration: 2000,
      });
      router.push({ path: '/draft' });
    }
  }).catch(err => {
    ElMessage({
      message: '草稿修改失败，请稍后重试',
      type: 'error',
      duration: 2000,
    });
  });
}



const store = useStore(); // 获取 Vuex store 实例
const token = store.state.token;
// 获取发件人邮箱（username）
const senderEmail = store.getters.getUsername; // 使用 getter 获取 username
//  // 构建附件数据对象数组
const attachments = fileList.value.map(file => ({
  name: file.name,
  size: file.size,
  type: file.type,
}));

//  发送按钮
const clickSend = () => {
  console.log("发送按钮被点击了");
  console.log(fileList.value);

  let attachmentssss = ''

  for (let i = 0; i < fileList.value.length; i++) {
    // const element = array[i];
    if ((i + 1) == fileList.value.length) {
      attachmentssss = attachmentssss + fileList.value[i].response.url.data
    } else {
      attachmentssss = attachmentssss + fileList.value[i].response.url.data + ","
    }
  }
  console.log("1", attachmentssss);

  // return
  // 准备发送邮件的数据
  const emailData = {
    from: senderEmail, // 发件人,
    to: recipient.value,// 收件人
    subject: subject.value,// 主题
    bodyHtml: valueHtml.value,// HTML 格式的邮件正文
    attachments: attachmentssss,// 附件列表
  };
  console.log("2", emailData);
  sendMailApi(emailData).then(res => {
    console.log("TAG 发送邮件结果", res);
    if (res.code === 200) {
      ElMessage({
        message: '邮件发送成功',
        type: 'success',
        duration: 2000,
      });
      router.push({ path: '/homePage' });
    }
  }).catch(err => {
    ElMessage({
      message: '邮件发送失败，请稍后重试',
      type: 'error',
      duration: 2000,
    });
  })
};



const api = ref("");
const emit = defineEmits(["updateContent"]);

//定义一下模式
const mode = ref("default");

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef(null);

// 内容 HTML
const valueHtml = ref("");

const toolbarConfig = toolbarConfigJs

const uploadLoding = ref(false);

const editorConfig = {
  placeholder: "请在此处编辑邮件内容...",
  MENU_CONF: {
    uploadImage: {
      // 超时时间，默认为 10 秒
      timeout: 30 * 1000, // 30 秒
      // server: env.baseUrl+"/user/upload/upload_file", // 上传图片接口 
      // fieldName: "file", // 上传图片字段名
      customInsert: (insertImg, result, editor) => {
        // 上传图片成功后，调用 insertImg(imgUrl) 插入图片到编辑器
        const url = result.data.url;
        insertImg(url);
      },
      async customUpload(file, insertFn) {
        const form = new FormData();

        // 将文件追加到 FormData 中
        form.append("file", file);

        // 计算文件的 MD5 哈希值
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
          const md5Hash = MD5(wordArray).toString();

          // 将 filename 和 md5filename 追加到 FormData 中
          // form.append("file", file.name);
          form.append("md5", md5Hash);
          const storeToken = store.state.token;
          const localToken = window.localStorage.getItem("vuex");
          let apiToken = ""
          if (storeToken) {
            apiToken = "Bearer " + storeToken
          } else if (localToken) {
            apiToken = "Bearer " + JSON.parse(localToken).token
          }

          try {
            uploadLoding.value = true;
            const response = await fetch(env.baseUrl + "/user/upload/upload_file", {
              method: "POST",
              headers: {
                "Authorization": apiToken
              },
              body: form,
            });

            const result = await response.json();
            console.log("上传结果:", result);
            uploadLoding.value = false;
            if (result && result.code == 200) {
              insertFn(env.baseUrl + "/static/" + result.data.url); // 将图片插入到编辑器中
            } else {
              console.error("上传失败:", result);
            }
          } catch (error) {
            console.error("上传出错:", error);
          }
        };

        // 读取文件为 ArrayBuffer 以计算 MD5
        reader.readAsArrayBuffer(file);
      },
    },
  },
};

const handleChanged = () => {
  // editor changed
  // console.log('content', valueHtml.value)
  emit("updateContent", valueHtml.value);
};

//父组件触发的来的函数
const setWangEditorContentNull = () => {
  valueHtml.value = "";
};

defineExpose({
  setWangEditorContentNull,
});

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
  // If there's existing content to be set, set it once editor is created
  if (valueHtml.value) {
    editor.setHtml(valueHtml.value);
  }
};
</script>

<style scoped lang="less">
.del {
  cursor: pointer;
}

.makeMail {
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.first-tit {
  color: #7686b4;
  font-weight: bold;
  margin-bottom: 25px;
}

.addressee {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: baseline;
}

.second-tit {
  margin-bottom: 25px;
  font-size: 14px;
  color: #a1abc2;
}

.mail-input {
  --el-border-color: transparent;
  --el-input-hover-border-color: transparent;
  --el-input-focus-border-color: transparent;
}

.upload-enclosure {
  display: flex;
  flex-direction: row;
}

.upload-file {
  margin-top: -13px;
  background-color: rgba(241, 247, 255, 1);
  padding: 10px;
  height: 60px;
  width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 7px;
}

.file-info {
  display: flex;
  flex-direction: column;
  width: 130px;
}

.rich-text {
  margin-top: 30px;
}

.main-btn {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.btn-item {
  background-color: #e4edf5;
  height: 40px;
  width: 80px;
  display: flex;
  // align-content: center;
  --el-border-color: #e4edf5;
  --el-button-active-border-color: #e4edf5;
}

.btn-item2 {
  background-color: #e4edf5;
  height: 40px;
  width: 100px;
  display: flex;
  // align-content: center;
  --el-border-color: #e4edf5;
  --el-button-active-border-color: #e4edf5;
}

.btn-item:hover {
  --el-button-hover-bg-color: #e4edf56b;
}

.mail-btn {
  background-color: #ea4237;
  height: 40px;
  width: 80px;
  display: flex;
  // align-content: center;
  --el-button-active-border-color: #ea4337cc;
}

.mail-btn:hover {
  --el-button-hover-bg-color: #ea4337cc;
}
</style>