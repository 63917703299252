import { createI18n } from "vue-i18n";
import Zh from "./locales/Ch";
import En from "./locales/En";
import Jp from "./locales/Ja";
import Ru from "./locales/Ru";
const i18n = createI18n({
    globalInjection: true,
    legacy: false, // 禁用 legacy 模式
    locale:localStorage.getItem('lang') || 'zh',//默认中文
    messages:{
        zh:Zh,
        en:En,
        jp:Jp,
        ru:Ru
    }
})
export default i18n;