import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/style/cssTable.css'
import vueI18n from '@/lang/index'


const app =  createApp(App)
app.use(store)
app.use(router)

app.use(ElementPlus)
app.use(vueI18n)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')