<script setup>
import router from "../router/index";
import { useStore } from "vuex";
import { onMounted, ref, nextTick,computed } from "vue";
import CustomWebSocket from "./websocket"

const viewloading = ref(false);
const store = useStore();
const userRole = computed(() => store.getters.getRole); // 通过 getter 获取角色

onMounted(() => {

  document.body.style.margin = '0px';
  // 监听路由变化
  router.afterEach((to, from) => {
    viewloading.value = true;
    nextTick(() => {
      viewloading.value = false;
    });
  });
  let ws = new CustomWebSocket();
  // 监听浏览器自定义事件
  window.addEventListener('closeWs', (e) => {
    ws.close()
  });

});

// const goToRegister = () => {
//   router.push('/register')
// }


</script>

<template>
  <div class="home">
    <el-container style="height: 100vh;">
      <el-aside width="200px" style="height: 100%;overflow-y: auto;">
        <el-scrollbar>
          <div class="aside-tit">
            <img class="tit-img" src="../assets/logo/logo1.png" alt="" />
            <div class="tit-text">
              <span style="font-size: 16px">{{$t("language.CorporateEmail")}}</span>
              <span style="color: #5a5a5c; font-size: 10px">email.apwise.cn</span>
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin: 20px 0">
            <router-link to="/makeMail" class="menu-link" active-class="active-link">
              <el-button class="mail-btn">
                <el-icon color="#fff" size="17px">
                  <EditPen style="font-weight: bold" />
                </el-icon>
                <span style="color: #fff; font-weight: bold">{{$t("language.WriteEmail")}}</span>
              </el-button>
            </router-link>
          </div>

          <el-menu router="true" :default-active="activeIndex" @select="handleMenuSelect">
            <el-menu-item index="/homePage">
              <el-icon>
                <location />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.home")}}</span>
            </el-menu-item>

            <el-menu-item index="/inbox">
              <el-icon>
                <location />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.inbox")}}</span>
            </el-menu-item>

            <el-menu-item index="/outbox">
              <el-icon>
                <location />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.outbox")}}</span>
            </el-menu-item>

            <el-menu-item index="/starMail">
              <el-icon>
                <setting />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.starred")}}</span>
            </el-menu-item>

            <el-menu-item index="/draft">
              <el-icon>
                <location />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.draft")}}</span>
            </el-menu-item>

            <el-menu-item index="/contacts">
              <el-icon>
                <setting />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.contact")}}</span>
            </el-menu-item>

            <el-menu-item v-if="userRole === 'admin'" index="/organizational">
              <el-icon>
                <setting />
              </el-icon>
              <span class="menu-text-item">{{$t("language.menu.user")}}</span>
            </el-menu-item>
          </el-menu>


          <!-- <el-button  type="primary" @click="goToRegister()">登录</el-button> -->



        </el-scrollbar>
      </el-aside>
      <!-- background-color: #fcfdff;  background-color: rgba(241, 247, 255, 1); #ecf5ff  #f1f7ff   linear-gradient(to right, #ff8a00, #da1b60)       -->
      <el-main class="home-main">
        <el-scrollbar v-loading="viewloading">
          <!-- 使用 <router-view> 动态显示路由组件 -->
          <!-- <div style="background-color: #fff;padding: 20px;"> -->
          <router-view></router-view>
          <!-- </div> -->

        </el-scrollbar>
      </el-main>
    </el-container>
  </div>
</template>



<style scoped lang="less">
.home {
  // height: 100vh;
  height: auto;
}

.aside-tit {
  // margin-top: 20px;
  padding-top: 20px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

.tit-img {
  height: 40px;
  width: 40px;
}

.tit-text {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

//.mail-btn {
//  background-color: #ea4237;
//  height: 40px;
//  width: 150px;
//  display: flex;
//  //align-content: center;
//  --el-button-active-border-color: #ea4337cc;
//  --el-border-radius-base: 6px;
//}
//.mail-btn:hover {
//  --el-button-hover-bg-color: #ea4337cc;
//}

:deep(.el-menu) {
  border-right: none;
}

:deep(.el-menu-item) {
  border-right: none;
  height: 65px;
  color: #656f87;
  --el-menu-active-color: #656f87;
}

// .menu-text-item {
//   // active-text-color:#656f87;
// }
.el-menu-item.is-active {
  background-color: #ecf5ff;
}

.menu-link {
  text-decoration: none;
}

.active-link {
  text-decoration: none;
}

.home-main {
  background: linear-gradient(to right, #ecf5ff, #fcfdff);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  --el-main-padding: 20px;
}




:deep(.el-scrollbar) {
  --el-scrollbar-bg-color: transparent;
  /* 设置滚动条背景为透明 */
}


.main-router {
  background-color: #fff;
  padding: 30px;
  margin: 20px;
  border-radius: 10px;
}
</style>
