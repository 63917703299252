export default {
    language: {
        CorporateEmail: "企业邮箱",
        WriteEmail: "写邮件",
        frontpage: "首页",
        login:"登录",
        Inbox: "收件箱",
        Outbox: "发件箱",
        StarredMail: "星标邮件",
        DraftBox: "草稿箱",
        Contact: "联系人",
        UserManagement: "用户管理",
        Unreademails: "未读邮件",
        search: "搜索发件人、邮件标题、附件等",
        today: "今天",
        yesterday: "昨天",
        Earlier: "更早",
        Sender: "发件人",
        delete: "删除",
        Sendto: "发送至",
        Noneinbox: "收件箱暂无邮件",
        NoneOutbox: "发件箱暂无邮件",
        NonestarMail: "暂无星标邮件",
        Nonedraft: "草稿箱暂无邮件",
        Deletedraft: "删除草稿",
        sealup: "封",
        Nocontactyet: "暂无联系人",
        Information: "联系人信息",
        him: "给TA写邮件",
        her: "与TA邮件往来",
        avatar: "头像",
        account: "账号",
        CreationTime: "创建时间",
        role: "管理员/用户",
        deletuser: "删除用户",
        recipient: "收件人",
        theme: "主题",
        Pleasefill:"请填写收件人邮箱",
        emailtitle:"请填写邮件标题",
        Add:"添加附件",
        Cancel:"取消",
        SaveDraft: "存草稿",
        Modifythedraft: "修改草稿",
        send: "发送",
        Nouser: "暂无用户",
        appendix: "附件",
        Replytoemail: "回复邮件",
        menu: {
            home: "首页",
            inbox: "收件箱",
            outbox: "发件箱",
            starred: "星标邮件",
            draft: "草稿箱",
            contact: "联系人",
            user: "用户管理",
        },
        mailValue: {
            title: "原始邮件内容",
            sender: "发件人",
            time: "时间",
            subject: "主题",
            reply: "回复",
        }
    }
}