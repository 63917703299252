<template>
  <div class="mailDetail">
    <el-container>
      <el-aside width="70%" style="margin-right: 15px">
        <div class="mail-content">
          <div class="content-tit">
            <!-- <el-icon @click="share" class="content-icon" size="24px" color="#a1abc2">
              <Share />
            </el-icon> -->
            <el-icon @click="starEmail" class="content-icon" size="24px" color="#a1abc2">
              <template v-if="starvul === 'true'">
                <StarFilled color="red" />
              </template>
              <template v-else>
                <Star />
              </template>
            </el-icon>
            <el-icon @click="del" class="content-icon" size="24px" color="#a1abc2">
              <DeleteFilled />
            </el-icon>
          </div>
          <div class="content-main">
            <el-scrollbar>
              <span v-html="list.body">
              </span>
            </el-scrollbar>
          </div>
        </div>
      </el-aside>
      <el-main width="27%" style="height: calc(100vh - 50px); background-color: #fff">
        <div class="mail-info">
          <div class="addressee">
            <div style="width: 56px">
              <span class="second-tit">{{ isInbox ? $t("language.Sender") : $t("language.Sendto") }}</span>
            </div>

            <div class="info-content-text1">
              <span class="mail-info-text" style="font-size: 14px; color: #656f87">
                < {{ isInbox ? list.sender : list.recipient }}>
              </span>
            </div>
          </div>
          <div class="addressee">
            <div style="width: 56px">
              <span class="second-tit">{{ $t("language.theme") }}</span>
            </div>
            <div>
              <span style="font-size: 15px; color: #22222b">{{ isInbox ? list.subject : list.subject
                }}</span>
            </div>
          </div>
          <div v-if="mailInfo && mailInfo.length > 0">
            <div class="upload-enclosure">
              <span class="first-tit" style="margin-right: 6px">{{ $t("language.appendix") }}</span>
            </div>
            <div class="upload-file" v-for="(attachment, index) in mailInfo" :key="index">
              <el-icon color="#cad0d8" size="40px">
                <Paperclip />
              </el-icon>
              <div class="file-info">
                <span style="color: #a1abc2; font-size: 14px">
                  {{ attachment.fileName }}
                </span>
                <span style="color: #a1abc2; font-size: 13px" v-if="no">
                  {{ (attachment.size / 1024 / 1024).toFixed(2) }} MB
                </span>
              </div>
              <el-icon @click.stop="upload(attachment)" color="#cad0d8" size="25px" v-if="no">
                <Download />
              </el-icon>
            </div>
          </div>

          <!-- 待编辑，待了解 -->
          <div v-if="isInbox" style="display: flex; justify-content: center; margin: 20px 0">
            <el-button class="mail-btn">
              <el-icon color="#fff" size="17px">
                <EditPen style="font-weight: bold" />
              </el-icon>
              <span style="color: #fff; font-weight: bold" @click.stop="goReply">{{ $t("language.Replytoemail")
                }}</span>
            </el-button>
          </div>

        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { receiveEmailDetailApi, sentEmailDetailApi, setStarApi } from '@/api/index';
import { downloadAppendix } from '@/utils/request'

const route = useRoute();
const router = useRouter()
const isInbox = ref(false); // 用来标识是否是从 inboxView 进入的详情页
const list = ref({});
const sourcevul = ref();
const idvul = ref();
const starvul = ref();
const attachments = ref([])
const mailInfo = ref([]);
const no = ref(true);

const goReply = () => {
  router.push({
    name: 'makeMail',
    params: {
      id: idvul.value
    }
  })
}

onMounted(() => {
  const { source, id, star } = route.query;
  console.log("TAG source", source, "id", id);
  sourcevul.value = source;
  idvul.value = id;
  starvul.value = star;
  console.log("TAG sourcevul", sourcevul.value, "idvul", idvul.value);
  console.log("TAG mailInfo", mailInfo.value);
  // 判断 source 来设置是否为 receive
  if (sourcevul.value === 'receive') {
    isInbox.value = true;
    receiveEmailDetailApi(idvul.value).then(res => {
      console.log("TAG 获取接收邮件详情 ", res)
      list.value = res.data
      attachments.value = res.data.attachments || []
      console.log("TAG list", list.value.attachments);
      if (list.value.attachments) {
        try {
          mailInfo.value = JSON.parse(list.value.attachments)
        } catch (e) {
          ElMessage.error('附件信息解析失败')
        }
      }
    })
  } else {
    sentEmailDetailApi(idvul.value).then(res => {
      console.log("TAG 获取发送邮件详情 ", res)
      list.value = res.data
      console.log("TAG 发送到邮件详情", res.data);
      if (res.data.attachments) {
        try {
          mailInfo.value = JSON.parse(res.data.attachments)
        } catch (e) {
          ElMessage.error('附件信息解析失败')
        }
        no.value = false;
      }
    })
  }
});


// 设为星标邮件
// const StarColor = ref(false)
const starEmail = () => {

  setStarApi({ id: list.value.id, type: sourcevul.value }).then(res => {
    if (res.code === 200) {
      starvul.value = starvul.value == 'true' ? 'false' : 'true';
    }

  })

};
// 删除邮件
const del = () => {
  console.log('点击删除邮件');
};
// 下载附件
const upload = (appendixPath) => {
  console.log('点击下载附件', appendixPath);
  window.open(appendixPath.downloadLink)
};
const reply = () => {
  console.log('点击回复邮件');
};


</script>


<style scoped lang="less">
.container_top_text {
  font-weight: bolder;
  font-family: 黑体, serif;
  font-size: 18px;
  color: #7686b4;
}

.mail-content {
  // height: 830px;
  // height: calc(100%-30px);
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.content-tit {
  cursor: pointer;
}

.content-icon {
  margin-right: 18px;
  size: 24px;
}

.content-main {
  // background-color: aquamarine;
  // height: 830px;
  height: calc(100vh - 148px);
  padding: 15px 0;
}

.addressee {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: baseline;
  align-items: center;
  margin-bottom: 25px;
}

.second-tit {
  // margin-bottom: 25px;
  font-size: 14px;
  color: #a1abc2;
}

.info-content-text1 {
  display: flex;
  align-items: center;
  background-color: #a1abc217;
  padding: 0 5px;
}

.upload-enclosure {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.upload-file {
  // margin-top: -13px;
  background-color: rgba(241, 247, 255, 1);
  padding: 10px;
  height: 60px;
  // width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 7px;
  cursor: pointer;
}

.file-info {
  display: flex;
  flex-direction: column;
  // width: 130px;
  width: 65%;
}

.first-tit {
  color: #7686b4;
  font-weight: bold;
  // margin-bottom: 25px;
}


.menu-link {
  text-decoration: none;
}

.active-link {
  text-decoration: none;
}
</style>