<template>
  <div class="starMail">
    <!--顶部-->
    <div class="container_top">
      <span class="container_top_text">{{$t("language.StarredMail")}}</span>
      <div style="float: right"></div>
    </div>
    <el-scrollbar v-if="hasDrafts&&!loading">
      <!-- 星标邮件列表 -->
      <div class="container_main1">
        <div class="have-mail" style="">
          <!-- 今天的星标邮件 -->
          <div class="today-drafts" v-if="todayDrafts.length > 0">
            <div style="display: flex; flex-direction: row; align-items: center">
              <p class="today-text">{{$t("language.today")}}</p>
              <span style="margin-left: 5px; color: #a1abc2">（{{ todayDrafts.length }} &nbsp;{{$t("language.sealup")}}）</span>
            </div>
            <table style="width: 100%; border-collapse: collapse; margin-top: 5px;cursor: pointer;"
              @click="jumpWriteEmail(item)" v-for="item in todayDrafts" :key="item">
              <tr class="table-content-row" style="
                text-align: left;
                width: 100%;
                border-bottom: 1px solid #152e4a1c;
              ">
                <td class="table-content" style="width: 3%">
                  <el-icon class="mail-icon" size="18px">
                    <Message />
                  </el-icon>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ item.recipients }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon class="mail-icon">
                    <!-- <Paperclip /> -->
                  </el-icon>
                </td>
                <td class="table-content" style="width: 57%">
                  <span class="table-content-text">{{ item.subject }}</span>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ formatDate(item.updated_at) }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon @click.stop="clickChangeColor(item)" class="mail-icon">
                    <template v-if="StarColor">
                      <Star />
                    </template>
                    <template v-else>
                      <StarFilled color="red" />
                    </template>
                  </el-icon>
                </td>
              </tr>
            </table>
          </div>

          <!-- 昨天的星标邮件 -->
          <div class="yesterday-drafts" v-if="yesterdayDrafts.length > 0">
            <div style="display: flex; flex-direction: row; align-items: center">
              <p class="yesterday-text">{{$t("language.yesterday")}}</p>
              <span style="margin-left: 5px; color: #a1abc2">（{{ yesterdayDrafts.length }} &nbsp;{{$t("language.sealup")}}）</span>
            </div>
            <table style="width: 100%; border-collapse: collapse; cursor: pointer; margin-top: 5px"
              @click="jumpWriteEmail(item)" v-for="item in yesterdayDrafts" :key="item">
              <tr class="table-content-row" style="
                text-align: left;
                width: 100%;
                border-bottom: 1px solid #152e4a1c;
              ">
                <td class="table-content" style="width: 3%">
                  <el-icon class="mail-icon" size="18px">
                    <Message />
                  </el-icon>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ item.recipients }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon class="mail-icon">
                    <!-- <Paperclip /> -->
                  </el-icon>
                </td>
                <td class="table-content" style="width: 57%">
                  <span class="table-content-text">{{ item.subject }}</span>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ formatDate(item.updated_at) }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon @click.stop="clickChangeColor(item)" class="mail-icon">
                    <template v-if="StarColor">
                      <Star />
                    </template>
                    <template v-else>
                      <StarFilled color="red" />
                    </template>
                  </el-icon>
                </td>
              </tr>
            </table>
          </div>


          <!-- 更早的星标邮件 -->
          <div class="previous-drafts" v-if="previousDrafts.length > 0">
            <div style="display: flex; flex-direction: row; align-items: center">
              <p class="previous-text">{{$t("language.Earlier")}}</p>
              <span style="margin-left: 5px; color: #a1abc2">（{{ previousDrafts.length }} &nbsp;{{$t("language.sealup")}}）</span>
            </div>
            <table style="width: 100%; border-collapse: collapse;cursor: pointer; margin-top: 5px"
              @click="jumpWriteEmail(item)" v-for="item in previousDrafts" :key="item">
              <tr class="table-content-row" style="
                text-align: left;
                width: 100%;
                border-bottom: 1px solid #152e4a1c;
              ">
                <td class="table-content" style="width: 3%">
                  <el-icon class="mail-icon" size="18px">
                    <Message />
                  </el-icon>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ item.recipients }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon class="mail-icon">
                    <!-- <Paperclip /> -->
                  </el-icon>
                </td>
                <td class="table-content" style="width: 57%">
                  <span class="table-content-text">{{ item.subject }}</span>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ formatDate(item.updated_at) }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon @click.stop="clickChangeColor(item)" class="mail-icon">
                    <template v-if="StarColor">
                      <Star />
                    </template>
                    <template v-else>
                      <StarFilled color="red" />
                    </template>
                  </el-icon>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <el-pagination v-if="total > 0" :current-page.sync="currentPage" :page-size="pageSize"
          layout="total, prev, pager, next, jumper" :total="total" @current-change="handlePageChange" :pager-count="10"
          hide-on-single-page>
        </el-pagination>
      </div>
    </el-scrollbar>
    <div v-else-if="loading">
      <el-skeleton :rows="5" animated />
    </div>
    <div class="nothing" v-else>
      <img src="../../assets/empty-page-bg/4.png" style="width: 500px;">
      <span class="nothing-text">{{$t("language.Noneinbox")}}</span>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { onMounted, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { getStarListApi, setStarApi, getDraftDetailApi, sentEmailDetailApi } from '@/api/index'
import dayjs from 'dayjs'


const loading = ref(true)

// 新增的分页相关的状态管理
const currentPage = ref(1)
const pageSize = ref(15);    // 每页显示的条数
const total = ref(0) // 用于存储总邮件数


onMounted(() => {
  fetchStarList()
})

// 获取星标邮件列表函数
const fetchStarList = () => {
  const params = {
    page: currentPage.value,
    limit: pageSize.value,

  }
  getStarListApi(params).then(res => {
    console.log('获取星标邮件列表成功', res.data)
    if (res.code == 200) {
      total.value = res.data.total // 设置总邮件数

      classifyMails(res.data.emails)
    }

  }).catch(err => {
    // ElMessage.error('星标邮件列表为空')
  }).finally(() => {
    loading.value = false
  })
}

// 计算属性：是否有星标邮件
const hasDrafts = computed(() => {
  if (todayDrafts.value.length > 0 || yesterdayDrafts.value.length > 0 || previousDrafts.value.length > 0) {
    return true
  } else {
    return false
  }
});

// 今天、昨天、更早的星标列表
const todayDrafts = ref([])
const yesterdayDrafts = ref([])
const previousDrafts = ref([])

const classifyMails = (mails) => {
  todayDrafts.value = []
  yesterdayDrafts.value = []
  previousDrafts.value = []

  const today = dayjs()
  const yesterday = dayjs().subtract(1, 'day')

  mails.forEach(mail => {
    const mailDate = dayjs(mail.updated_at)

    if (mailDate.isSame(today, 'day')) {
      todayDrafts.value.push(mail)
    } else if (mailDate.isSame(yesterday, 'day')) {
      yesterdayDrafts.value.push(mail)
    } else {
      previousDrafts.value.push(mail)
    }
  })
}

// 日期处理
const formatDate = (dateString) => {
  const date = dayjs(dateString)
  const now = dayjs()

  if (date.isSame(now, 'day')) {
    // 今天
    return date.format('HH:mm')
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    // 昨天
    return `昨天 ${date.format('HH:mm')}`
  } else {
    // 更早
    return date.format('YYYY-MM-DD')
  }
}

const router = useRouter()
// 跳转到写邮件页面

const jumpWriteEmail = (item) => {
  console.log('跳转到写邮件页面', item)


  if (item.type === 'sent') {
    const Details2 = {
      source: 'sent',
      id: item.id,
      star: item.star
    }
    router.push({
      path: '/mailDetails',
      query: JSON.parse(JSON.stringify(Details2))
    })
  } else if (item.type === 'receive') {

    const Details = {
      source: 'receive',
      id: item.id,
      star: item.star
    }
    router.push({
      path: '/mailDetails',
      query: JSON.parse(JSON.stringify(Details))
    })
  } else {

    // 准备要传递给 makeMail 页面的草稿内容
    const draftContent = {
      id: item.id,
      subject: item.subject,
      body: '', // 邮件内容
      recipients: item.recipients,
    };
    getDraftDetailApi(item.id).then((res) => {
      console.log("TAG 获取草稿详情", res);
      draftContent.body = res.data.body
      // 使用 Vue Router 跳转到 makeMail 页面，并传递草稿内容
      router.push({
        path: '/makeMail',
        query: draftContent // 通过 query 传递草稿内容
      });
    })
      .catch((err) => {
        console.error("获取详情失败", err);
        // 可以在此处理错误，例如显示提示信息
        ElMessage({
          message: '获取详情失败，请稍后重试',
          type: 'error',
          duration: 2000,
        });
      });
  }


}
// 点击改变颜色
const clickChangeColor = (item) => {
  console.log('点击改变颜色/设为星标邮件', item)
  setStarApi({ id: item.id, type: item.type }).then(res => {
    console.log('设置星标邮件成功', res.data)
    if (res.code === 200) {
      ElMessage.success('取消星标邮件成功')
    }
    fetchStarList()
  }).catch(err => {
    ElMessage.error('设置星标邮件失败')
  })
}

// 处理分页改变
const handlePageChange = (page) => {
  currentPage.value = page
  fetchStarList()
}
</script>




<style scoped lang="less">
.container_top {
  height: 50px;
  background: #ffffff;
  // margin: 15px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 8px;
}


.have-mail {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.today-text {
  color: #7686b4;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.table-content {
  height: 38px;
  display: inline-flex;
  align-items: center;
}

.table-content-text {
  font-size: 14px;
  color: #a1abc2;
}

.checkbox {
  --el-border: var(--el-border-width) var(--el-border-style) #a1abc2;
}

.mail-icon {
  color: #a1abc2;
}

.yesterday-drafts {
  margin-top: 10px;
}

.yesterday-text {
  color: #7686b4;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.previous-drafts {
  margin-top: 10px;
}

.parent-container {
  /* 父容器样式 */
  position: relative;
  min-height: 100vh;
  /* 保证容器至少和视口一样高 */
  padding-bottom: 50px;
  /* 预留空间给pagination */
}


.nothing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previous-text {
  color: #7686b4;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.el-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  /* 确保它在其他内容之上 */
  // background-color: white; /* 背景颜色，可以根据需求调整 */
  // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* 阴影效果，可选 */
  display: flex;
  justify-content: center;
}
</style>