<template>
  <div class="personalInfo">
    <div class="container_top">
      <span class="container_top_text">个人页面</span>

    </div>
    <el-button type="danger" @click="quit">退出登录</el-button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const quit = () => {
  console.log("TAG 退出登录");

  // 清除 Vuex 中的登录状态
  store.commit('delToken'); // 清除 token 和登录状态
  window.localStorage.clear();
  
  // 调用浏览器自定义事件
  window.dispatchEvent(new Event('closeWs'));

  // 跳转到登录页面
  router.push('/register');
}
</script>


<style scoped lang="less">
.container_top {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 8px;
  background: #ffffff;
  //display: inline-flex;
  //flex-direction: row;
  //align-items: center;
}
</style>