import i18n from '../../lang/index'
export const defaultHtml = ({ body, sender, subject, receipt_at }) => {
    return `<p><br/></p><p><br/></p><p>----------------------${i18n.global.t('language.mailValue.title')}---------------------</p><p><span style="background-color: #efefef;">${i18n.global.t('language.mailValue.sender')}：&lt;${sender}&gt;;</span></p><p><span style="background-color: #efefef;">${i18n.global.t('language.mailValue.time')}：${receipt_at};</span></p><p><span style="background-color: #efefef;">${i18n.global.t('language.mailValue.subject')}：${subject};</span></p><p><br /></p>${body}`
}
export const loadinSvg = `
<path class="path" d="
  M 30 15
  L 28 17
  M 25.61 25.61
  A 15 15, 0, 0, 1, 15 30
  A 15 15, 0, 1, 1, 27.99 7.5
  L 15 15
" style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
`