import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import inboxView from '../views/inbox/inboxView.vue'
import starMailView from '../views/starMail/starMailView.vue'
import draftsView from '../views/draft/draftsView.vue'
import contactsView from '../views/contacts/contactsView.vue'
import makeMailView from '../views/makeMail/makeMailView.vue'
import mailDetailsView from '../views/details/mailDetailsView.vue'
import personalInfoView from '../views/personalInfo/personalInfoView.vue'
import store from '../store'




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/homePage',
    children: [
      {
        // 首页
        path: '/homePage',
        name: 'homePage',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/home/homePageView.vue')
      },
      {
        // 收件箱
        path: '/inbox',
        name: 'inbox',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/inbox/inboxView.vue')
      },
      {
        // 收件箱
        path: '/outbox',
        name: 'outbox',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/outbox/outboxView.vue')
      },
      {
        // 星标邮件
        path: '/starMail',
        name: 'starMail',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/starMail/starMailView.vue')
      },
      {
        // 垃圾箱
        path: '/draft',
        name: 'draft',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/draft/draftsView.vue')
      },
      {
        // 联系人
        path: '/contacts',
        name: 'contacts',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/contacts/contactsView.vue')
      },
      {
        // 写邮件
        path: '/makeMail/:id?/:email?/:sendto?/:sendname?',
        name: 'makeMail',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/makeMail/makeMailView.vue')
      },
      {
        // 查看邮件详情内容
        path: '/mailDetails',
        name: 'mailDetails',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/details/mailDetailsView.vue')
      },
      {
        // 用户管理
        path: '/organizational',
        name: 'organizational',
        meta: {
          rule: 'admin'
        },
        component: () => import('../views/organizational/organizationalView.vue')
      },
      {
        // 个人信息
        path: '/personalInfo',
        name: 'personalInfo',
        meta: {
          rule: 'all'
        },
        component: () => import('../views/personalInfo/personalInfoView.vue')
      }
    ]
  },
  {
    // 登录页面
    path: '/register',
    name: 'register',
    meta: {
      rule: 'all'
    },
    component: () => import('../views/login/registerView.vue')
  },
  {
    // 登录页面
    path: '/404',
    name: '404',
    meta: {
      rule: 'all'
    },
    component: () => import('../views/system/404.vue')
  },
  {
    // 找不到路由重定向到404页面
    path: "/:pathMatch(.*)",
    redirect: "/404",
    meta: { rule: 'all' },
    component: () => import('../views/system/404.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 添加全局导航守卫
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.getIsLoggedIn;
  const getRole = store.getters.getRole;
  if (to.path === '/register' && isLoggedIn) {
    // 如果用户已经登录，且试图访问登录页面，重定向到主页
    next('/homePage');
  } else if (!isLoggedIn && to.path !== '/register') {
    // 如果用户未登录，且试图访问非登录页面，重定向到登录页面
    next('/register');
  } else if (to.meta.rule != "all" && getRole != to.meta.rule) {
    next('/404');  //权限控制 重定向到主页
  } else {
    next(); // 继续导航
  }
});

export default router
