import env from "../utils/config"
import store from "@/store";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
const HEARTTIME = 60000; //心跳时间 60s
// const HEARTTIME = 5000; //心跳时间 5s
const MAXTIME = 3; //最大重连次数
class CustomWebSocket {
    constructor() {
        if (!store.state.token && !window.localStorage.getItem("vuex")) {
            ElMessage.error("请先登录");
            return
        }
        if (store.state.token) {
            this.url = `${env.wsUrl}?token=Bearer ${store.state.token}`;
        }else{
            const localToken = JSON.parse(window.localStorage.getItem("vuex"))
            this.url = `${env.wsUrl}?token=Bearer ${localToken.token}`;
        }
        this.ws = null;
        this.init();
        this.reConnectCount = 0
    }
    init() {
        this.ws = new WebSocket(this.url);
        this.ws.onopen = this.onopen;
        this.ws.onmessage = this.onmessage;
        this.ws.onclose = this.onclose;
        this.ws.onerror = this.onerror;
    }
    // 监听到ws连接打开
    onopen = () => {
        console.log("连接成功", this);
        // 心跳检测
        this.heartCheck();
        // 请求浏览器通知权限
        Notification.requestPermission().then(permission => {
            console.log("TAG 权限", permission);
            if (permission === "granted") {
                console.log("TAG 权限已授予");
            }
        });
    }
    // 监听到ws消息
    onmessage = (event) => {
        console.log("收到消息", event.data);
        if (event.data === "PONG") {
            console.log("TAG 心跳正常");
            return;
        }
        // 处理消息
        ElNotification({
            title: '提醒',
            message: event.data,
            type: 'info',
        })
        if (Notification.permission === 'granted') {
            // 发送浏览器通知
            new Notification("提醒", {
                body: event.data,
            });
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                console.log("TAG 权限", permission);
                if (permission === "granted") {
                    console.log("TAG 权限已授予");
                }
            });
        }
    }
    // 监听到ws关闭
    onclose = (err) => {
        console.log("连接关闭", err);
    }
    // 监听到ws错误
    onerror = (err) => {
        // 处理重连
        this.reConnect();
    }
    heartCheck = () => {
        this.pingInterval = setInterval(() => {
            this.ws.send("PING");
        }, HEARTTIME);
    }
    reConnect = () => {

        if (this.reConnectCount >= MAXTIME) {
            ElMessageBox.alert("连接失败，请检查网络", "提示", {
                confirmButtonText: "确定",
                callback: action => {
                }
            });
            return
        }

        ElMessage.error("连接失败，正在重连 当前重连次数" + this.reConnectCount + "最大重连次数" + MAXTIME);
        clearInterval(this.pingInterval); // 清除定时器
        this.ws = null;
        this.ws = new WebSocket(this.url);
        this.ws.onopen = this.onopen;
        this.ws.onmessage = this.onmessage;
        this.ws.onclose = this.onclose;
        this.ws.onerror = this.onerror;
        this.reConnectCount += 1
    }
    // 关闭ws
    close = () => {
        this.ws.close();
        clearInterval(this.pingInterval);
    }
}
export default CustomWebSocket;