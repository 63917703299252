export default {
    language: {
        CorporateEmail: "Corporate Email",
        WriteEmail: "Write an email",
        frontpage: "front page",
        login:"Log in",
        Unreademails: "Unread emails",
        search: "Search sender, email title, attachments, etc.",
        today: "today",
        yesterday: "yesterday",
        Earlier: "Earlier",
        Sender: "Sender",
        delete: "Delete",
        Sendto: "Send to",
        Noneinbox: "There is no email in your inbox",
        NoneOutbox: "There is no email in the outbox",
        NonestarMail: "No starred emails",
        Nonedraft: "There are no emails in the draft box",
        Deletedraft: "Delete draft",
        sealup: "seal up",
        Nocontactyet: "No contact yet",
        Information: "Contact information",
        him: "Write an email to him",
        her: "Email with him/her",
        avatar: "Avatar",
        account: "Account",
        CreationTime: "Creation time",
        role: "Administrator/User",
        deletuser: "Delete user",
        recipient: "Recipient",
        theme: "Theme",
        Pleasefill:"Please fill in the recipient's email",
        emailtitle:"Please fill in the email title",
        Add:"Add attachment",
        Cancel:"Cancel",
        SaveDraft: "Save draft",
        Modifythedraft: "Modify the draft",
        send: "Send",
        Nouser: "No user",
        appendix: "Attachment",
        Replytoemail: "Reply to email",
        menu: {
            home: "home",
            inbox: "inbox",
            outbox: "outbox",
            starred: "starred",
            draft: "draft",
            contact: "contact",
            user: "user",
        },
        mailValue: {
            title: "Original email content",
            sender: "Sender",
            time: "Time",
            subject: "Subject",
            reply: "Re",
        }
    }
}