export default {
    language: {
        CorporateEmail: "ビジネスメール",
        WriteEmail: "メールを書く",
        frontpage: "フロントページ",
        login:"ログイン",
        Unreademails: "未読メール",
        search: "送信者、メールヘッダー、添付ファイルなどを検索します",
        today: "今日",
        yesterday: "昨日",
        Earlier: "前に",
        Sender: "差出人",
        delete: "消去",
        Sendto: "送信先",
        Noneinbox: "受信箱にはまだメールがありません",
        NoneOutbox: "送信トレイにまだメールがありません",
        NonestarMail: "スター付きメッセージはまだありません",
        Nonedraft: "下書きボックスにはまだメールがありません",
        Deletedraft: "下書きの削除",
        sealup: "封印する",
        Nocontactyet: "まだ連絡がありません",
        Information: "連絡先",
        him: "TA にメールを書く",
        her: "TAとメールでやりとりする",
        avatar: "アバター",
        account: "アカウント",
        CreationTime: "作成時間",
        role: "マネージャー-ユーザー",
        deletuser: "ユーザーの削除",
        recipient: "受信者",
        theme: "テーマ",
        Pleasefill:"受信者のメールアドレスを入力してください",
        emailtitle:"メールのタイトルを入力してください",
        Add:"添付ファイルを追加",
        Cancel:"キャンセル",
        SaveDraft: "下書きを保存",
        Modifythedraft: "下書きを修正",
        send: "送信",
        Nouser: "ユーザーはいません",
        appendix: "添付ファイル",
        Replytoemail: "メールに返信",
        menu: {
            home: "フロントページ",
            inbox: "受信箱",
            outbox: "送信ボックス",
            starred: "スターメール",
            draft: "ドラフトボックス",
            contact: "連絡担当者",
            user: "ユーザー管理",
        },
        mailValue: {
            title: "元のメールコンテンツ",
            sender: "差出人",
            time: "時間",
            subject: "主題",
            reply: "返信",
        }
    }
}