<template>
  <div class="homePage">
    <!--顶部-->
    <div class="container_top">
      <el-checkbox class="checkbox" v-model="checkedAll" @change="handleCheckAllChange" label="" />
      <span class="container_top_text">{{$t("language.DraftBox")}}</span>
      <div style="float: right"></div>
      <el-button class="draft-btn" color="#ecf5ff" @click="delDraft">{{$t("language.Deletedraft")}}</el-button>
    </div>
    <el-scrollbar v-if="hasDrafts&&!loading">
      <!-- 草稿邮件列表 -->
      <div class="container_main1">


        <!-- 草稿邮件列表内邮件  -->
        <div class="have-mail" style="">

          <!-- 今天的草稿邮件 -->
          <div class="today-drafts" v-if="todayDrafts.length > 0">
            <div style="display: flex; flex-direction: row; align-items: center">
              <p class="today-text">{{$t("language.today")}}</p>
              <span style="margin-left: 5px; color: #a1abc2">（{{ todayDrafts.length }} &nbsp;{{$t("language.sealup")}}）</span>
            </div>
            <table @click="jumpWriteEmail(item)"
              style=" table-layout: fixed;width: 100%; border-collapse: collapse; margin-top: 5px;cursor: pointer;"
              v-for="(item, index) in todayDrafts" :key="index">
              <tr class="table-content-row" style="
                text-align: left;
                width: 100%;
                border-bottom: 1px solid #152e4a1c;
              ">
                <td class="table-content" style="width: 3%">
                  <el-checkbox @click.stop class="checkbox" v-model="checkedItems" :label="item.id">
                    <!-- 使用一个空的插槽来覆盖默认的 label 渲染 -->
                    <template v-slot:default></template>
                  </el-checkbox>
                </td>
                <td class="table-content" style="width: 3%">
                  <el-icon class="mail-icon" size="18px">
                    <Message />
                  </el-icon>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ item.recipients }}</span>
                </td>
                <td class="table-content" style="width: 2%;padding-left: 30px;">
                  <el-icon class="mail-icon">
                    <!-- <Paperclip /> -->
                  </el-icon>
                </td>
                <td class="table-content" style="width: 57%">
                  <span class="table-content-text" style="color: black;">{{ item.subject }}</span>
                  <!-- <span class="table-content-text">--- {{ item.body }}</span> -->
                </td>
                <td class="table-content" style="width: 10%;padding-left: 50px;">
                  <span class="table-content-text">{{ item.formattedTime }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon @click.stop="clickChangeColor(item)" class="mail-icon">
                    <template v-if="item.StarColor === 'false'">
                      <Star />
                    </template>
                    <template v-else>
                      <StarFilled color="red" />
                    </template>
                  </el-icon>
                </td>
              </tr>
            </table>
          </div>

          <!-- 昨天的草稿邮件 -->
          <div class="yesterday-drafts" v-if="yesterdayDrafts.length > 0">
            <div style="display: flex; flex-direction: row; align-items: center">
              <p class="yesterday-text">{{$t("language.yesterday")}}</p>
              <span style="margin-left: 5px; color: #a1abc2">（{{ yesterdayDrafts.length }} &nbsp;{{$t("language.sealup")}}）</span>
            </div>
            <table @click="jumpWriteEmail(item)"
              style=" table-layout: fixed;width: 100%; border-collapse: collapse; margin-top: 5px;cursor: pointer;"
              v-for="(item, index) in yesterdayDrafts" :key="index">
              <tr class="table-content-row" style="
                text-align: left;
                width: 100%;
                border-bottom: 1px solid #152e4a1c;
              ">
                <td class="table-content" style="width: 3%">
                  <el-checkbox @click.stop class="checkbox" v-model="checkedItems" :label="item.id">
                    <!-- 使用一个空的插槽来覆盖默认的 label 渲染 -->
                    <template v-slot:default></template>
                  </el-checkbox>
                </td>
                <td class="table-content" style="width: 3%">
                  <el-icon class="mail-icon" size="18px">
                    <Message />
                  </el-icon>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ item.recipients }}</span>
                </td>
                <td class="table-content" style="width: 2%;padding-left: 30px;">
                  <el-icon class="mail-icon">
                    <!-- <Paperclip /> -->
                  </el-icon>
                </td>
                <td class="table-content" style="width: 57%">
                  <span class="table-content-text" style="color: black;">{{ item.subject }}</span>
                  <!-- <span class="table-content-text">--- {{ item.body }}</span> -->
                </td>
                <td class="table-content" style="width: 10%;padding-left: 50px">
                  <span class="table-content-text">{{ item.formattedTime }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon @click.stop="clickChangeColor(item)" class="mail-icon">
                    <template v-if="item.StarColor === 'false'">
                      <Star />
                    </template>
                    <template v-else>
                      <StarFilled color="red" />
                    </template>
                  </el-icon>
                </td>
              </tr>
            </table>
          </div>


          <!-- 更早的草稿邮件 -->
          <div class="previous-drafts" v-if="previousDrafts.length > 0">
            <div style="display: flex; flex-direction: row; align-items: center">
              <p class="previous-text">{{$t("language.Earlier")}}</p>
              <span style="margin-left: 5px; color: #a1abc2">（{{ previousDrafts.length }} &nbsp;{{$t("language.sealup")}}）</span>
            </div>
            <table @click="jumpWriteEmail(item)"
              style=" table-layout: fixed;width: 100%; border-collapse: collapse; margin-top: 5px;cursor: pointer;"
              v-for="(item, index) in previousDrafts" :key="index">
              <tr class="table-content-row" style="
                text-align: left;
                width: 100%;
                border-bottom: 1px solid #152e4a1c;
              ">
                <td class="table-content" style="width: 3%">
                  <el-checkbox @click.stop class="checkbox" v-model="checkedItems" :label="item.id">
                    <!-- 使用一个空的插槽来覆盖默认的 label 渲染 -->
                    <template v-slot:default></template>
                  </el-checkbox>
                </td>
                <td class="table-content" style="width: 3%">
                  <el-icon class="mail-icon" size="18px">
                    <Message />
                  </el-icon>
                </td>
                <td class="table-content" style="width: 15%">
                  <span class="table-content-text">{{ item.recipients }}</span>
                </td>
                <td class="table-content" style="width: 2%;padding-left: 30px;">
                  <el-icon class="mail-icon">
                    <!-- <Paperclip /> -->
                  </el-icon>
                </td>
                <td class="table-content" style="width: 57%">
                  <span class="table-content-text" style="color: black;">{{ item.subject }}</span>
                  <!-- <span class="table-content-text">--- {{ item.body }}</span> -->
                </td>
                <td class="table-content" style="width: 10%;padding-left: 50px">
                  <span class="table-content-text">{{ item.formattedTime }}</span>
                </td>
                <td class="table-content" style="width: 2%">
                  <el-icon @click.stop="clickChangeColor(item)" :type="type" class="mail-icon">
                    <template v-if="item.StarColor === 'false'">
                      <Star />
                    </template>
                    <template v-else>
                      <StarFilled color="red" />
                    </template>
                  </el-icon>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <el-pagination v-if="total > 0" :current-page.sync="currentPage" :page-size="pageSize"
          layout="total, prev, pager, next, jumper" :total="total" @current-change="handlePageChange" :pager-count="10"
          hide-on-single-page>
        </el-pagination>
      </div>

    </el-scrollbar>

    <!-- 草稿邮件列表内暂无邮件  -->
    <div v-else-if="loading">
      <!-- <img src="../../assets/empty-page-bg/4.png" style="width: 500px;"> -->
      <!-- <span class="nothing-text">暂无草稿邮件</span> -->
      <el-skeleton :rows="5" animated />
    </div>
    <div class="nothing" v-else>
      <img src="../../assets/empty-page-bg/4.png" style="width: 500px;"> 
       <span class="nothing-text">{{$t("language.Nonedraft")}}</span>
      <!-- <el-skeleton :rows="5" animated /> -->
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted, ref, watch, computed } from 'vue'
import { getDraftListApi, deleteDraftApi, getDraftDetailApi, setStarApi } from '@/api/index'
import { Message, Paperclip, Star, StarFilled } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { useRoute } from 'vue-router'

const loading = ref(true)
const router = useRouter()
const checkedItems = ref([]) // 保存选中的草稿ID
const checkedAll = ref(false) // 全选控制


// 计算属性：是否有草稿
const hasDrafts = computed(() => {
  if (todayDrafts.value.length > 0 || yesterdayDrafts.value.length > 0 || previousDrafts.value.length > 0) {
    return true
  } else {
    return false
  }
});


// 今天、昨天、更早的草稿列表
const todayDrafts = ref([]);
const yesterdayDrafts = ref([]);
const previousDrafts = ref([]);

// 日期处理
const formatDate = (dateString) => {
  const date = dayjs(dateString);
  const now = dayjs();

  if (date.isSame(now, 'day')) {
    // 今天
    return date.format('HH:mm');
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    // 昨天
    return `昨天 ${date.format('HH:mm')}`;
  } else {
    // 更早
    return date.format('YYYY-MM-DD');
  }
};

const currentPage = ref(1);  // 当前页码
const pageSize = ref(15);    // 每页显示的条数
const total = ref(0);  // 总记录数

// 更新分页数据
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchDrafts();  // 切换页码时重新获取草稿列表
};



// 封装获取草稿列表的逻辑
const fetchDrafts = () => {
  todayDrafts.value = [];
  yesterdayDrafts.value = [];
  previousDrafts.value = [];

  getDraftListApi({ page: currentPage.value, limit: pageSize.value }).then((res) => {
    console.log("TAG 获取草稿列表", res);
    const drafts = res.data.drafts;
    total.value = res.data.total;  // 更新总记录数
    drafts.forEach((item) => {
      console.log('item 中的数据', item);
      const formattedTime = formatDate(item.updated_at);
      item.formattedTime = formattedTime;



      // 初始化 StarColor
      item.StarColor = item.star;
      console.log(item.StarColor);


      const itemDate = dayjs(item.updated_at);
      const now = dayjs();

      if (itemDate.isSame(now, 'day')) {
        todayDrafts.value.push(item);
      } else if (itemDate.isSame(now.subtract(1, 'day'), 'day')) {
        yesterdayDrafts.value.push(item);
      } else {
        previousDrafts.value.push(item);
      }
    });

    // 更新全选状态
    updateCheckAllStatus();
  }).catch(err => {
    ElMessage({
      message: '获取草稿列表失败',
      type: 'error',
      duration: 2000,
    });
  }).finally(()=>{
    loading.value = false
  })
};

// 更新全选状态的函数
const updateCheckAllStatus = () => {
  const allItems = [...todayDrafts.value, ...yesterdayDrafts.value, ...previousDrafts.value];
  checkedAll.value = checkedItems.value.length > 0 && checkedItems.value.length === allItems.length;
};

onMounted(() => {
  fetchDrafts();
});


const handleCheckAllChange = (val) => {
  const allItems = [...todayDrafts.value, ...yesterdayDrafts.value, ...previousDrafts.value];
  if (allItems.length === 0) {
    ElMessage({
      message: '没有可选项',
      type: 'warning',
      duration: 2000,
    });
    checkedAll.value = false;
    return;
  }
  checkedItems.value = val ? allItems.map(item => item.id) : [];
};


watch(checkedItems, (newVal) => {
  const allItems = [...todayDrafts.value, ...yesterdayDrafts.value, ...previousDrafts.value];

  // 当所有项目都被选中时，checkedAll 应该为 true，否则为 false
  checkedAll.value = newVal.length === allItems.length;
});

// 删除草稿
const delDraft = () => {
  console.log('删除草稿被点击')
  if (checkedItems.value.length === 0) {
    console.log("请选择要删除的草稿")
    ElMessage({
      message: '请选择要删除的草稿',
      type: 'warning',
      duration: 2000, // 持续时间（毫秒）
    })
    return
  }
  const idsString = checkedItems.value.join(',') // 将数组转换为字符串
  deleteDraftApi(idsString).then((res) => {
    console.log("TAG 删除草稿", res);
    // 删除成功后，刷新草稿列表或移除已删除的草稿
    if (res.code === 200) {
      ElMessage({
        message: '草稿删除成功',
        type: 'success',
        duration: 2000,
      });
      fetchDrafts();  // 删除成功后刷新草稿列表
      checkedItems.value = []; // 清空选中的项目
      updateCheckAllStatus(); // 删除成功后更新全选状态
    }
  })
}



// 跳转到写邮件页面
const jumpWriteEmail = (item) => {
  console.log('跳转到写邮件页面', item)

  // 准备要传递给 makeMail 页面的草稿内容
  // const draftContent = {
  //   id: item.id,
  //   // subject: item.subject,
  //   // file:item.attachments,
  //   // body: '', // 邮件内容
  //   // recipients: item.recipients,
  // };
  // console.log('draftContent', draftContent);
  
  // getDraftDetailApi(item.id).then((res) => {
  //   console.log("TAG 获取草稿详情", res);
  //   draftContent.body = res.data.body
    // 使用 Vue Router 跳转到 makeMail 页面，并传递草稿内容
    router.push({
      path: '/makeMail',
      query: { id: item.id,recipients: item.recipients} // 通过 query 传递草稿内容
    });
  // }).catch((err) => {
  //   console.error("获取草稿详情失败", err);
  //   // 可以在此处理错误，例如显示提示信息
  //   ElMessage({
  //     message: '获取草稿详情失败，请稍后重试',
  //     type: 'error',
  //     duration: 2000,
  //   });
  // });


}
// 获取路由对象
const route = useRoute()
// 点击改变颜色/设为星标邮件
const clickChangeColor = (item) => {
  console.log('点击改变颜色/设为星标邮件')
  const newStarStatus = !item.StarColor;
  const type = route.path.split('/').pop(); // 从路径中提取类型，例如 'drafts'
  setStarApi({ id: item.id, type: type }).then((res) => {
    console.log("TAG 设置星标邮件", item.id, res);
    if (res.code === 200) {
      ElMessage({
        message: item.StarColor === 'false' ? '设置星标邮件成功' : '星标已取消',
        type: 'success',
        duration: 2000,
      });
      item.StarColor = newStarStatus
      fetchDrafts();
    }
  })
}
</script>


<style scoped lang="less">
.container_top {
  height: 50px;
  background: #ffffff;
  width: 100%;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
}


.draft-btn {
  margin-left: 10px;
  color: #7686b4;
}

.draft-btn:hover {
  color: #7686b4;
  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.1);
}


.have-mail {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.today-text {
  color: #7686b4;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}


.table-content {
  height: 38px;
  display: inline-flex;
  align-items: center;
}

.table-content-text {
  font-size: 14px;
  color: #a1abc2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  --el-border: var(--el-border-width) var(--el-border-style) #a1abc2;
}

.mail-icon {
  color: #a1abc2;
  cursor: pointer;
}

.yesterday-drafts {
  margin-top: 10px;
}

.yesterday-text {
  color: #7686b4;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.previous-drafts {
  margin-top: 10px;
}

.previous-text {
  color: #7686b4;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.nothing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parent-container {
  /* 父容器样式 */
  position: relative;
  min-height: 100vh;
  /* 保证容器至少和视口一样高 */
  padding-bottom: 50px;
  /* 预留空间给pagination */
}

.el-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  /* 确保它在其他内容之上 */
  // background-color: white; /* 背景颜色，可以根据需求调整 */
  // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* 阴影效果，可选 */
  display: flex;
  justify-content: center;
}
</style>