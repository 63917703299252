export default {
    language: {
        CorporateEmail: "предприятие Почта",
        WriteEmail: "написать электронное письмо",
        frontpage: "первая страница",
        login:"Авторизоваться",
        Unreademails: "Непрочитанные письма",
        search: "Поиск отправителей, заголовков электронных писем, вложений и т. д.",
        today: "сегодня",
        yesterday: "вчера",
        Earlier: "ранее",
        Sender: "отправитель",
        delete: "удалить",
        Sendto: "Отправить",
        Noneinbox: "Во входящих письмах пока нет",
        NoneOutbox: "В исходящих письмах еще нет",
        NonestarMail: "Помеченных сообщений пока нет",
        Nonedraft: "В ящике черновиков еще нет писем",
        Deletedraft: "Удалить черновик",
        sealup: "запечатать",
        Nocontactyet: "Пока нет контакта",
        Information: "Контактная информация",
        him: "Напишите письмо ТА",
        her: "Общайтесь с ТА по электронной почте",
        avatar: "аватар",
        account: "время создания",
        CreationTime: "创建时间",
        role: "Администратор-Пользователь",
        deletuser: "Удалить пользователя",
        recipient: "Получатель",
        theme: "Тема",
        Pleasefill:"Пожалуйста, заполните адрес электронной почты получателя",
        emailtitle:"Пожалуйста, заполните тему письма",
        Add:"Добавить вложение",
        Cancel:"отменить",
        SaveDraft: "Сохранить черновик",
        Modifythedraft: "Изменить черновик",
        send: "отправить",
        Nouser: "Нет пользователя",
        appendix: "вложение",
        Replytoemail: "Ответить на письмо",
        menu: {
            home: "первая страница",
            inbox: "Входящие",
            outbox: "исходящие",
            starred: "Звездная почта",
            draft: "черновой ящик",
            contact: "Контактное лицо",
            user: "Управление пользователями",
        },
        mailValue: {
            title: "Исходное содержание электронного письма",
            sender: "отправитель",
            time: "время",
            subject: "тема",
            reply: "ответить",
        }
    }
}