import { post } from '../utils/request';

// 登录api
export const loginApi = (username, password) => {
    return post("/user/registerlogin/login", { username, password })
}
// 保存草稿API
export const saveDraftApi = (data) => {
    return post("/user/draft/save_draft", data)
}
// 修改草稿API
export const updateDraftApi = (data) => {
    return post("/user/draft/modify_draft", data)
}
// 删除草稿API
export const deleteDraftApi = (id) => {
    return post("/user/draft/delete_draft", { id })
}
// 获取草稿箱列表
export const getDraftListApi = (data) => {
    return post("/user/draft/draft_list",data)
}
// 获取草稿详情
export const getDraftDetailApi = (id) => {
    return post("/user/draft/get_data", { id })
}
// 设置取消星标api
export const setStarApi = data => {
    return post("/user/star/set_star", data)
}
// 获取星标列表
export const getStarListApi = (data) => {
    return post("/user/star/star_list", data)
}
// 邮件发送
export const sendMailApi = (data) => {
    return post("/user/emails/send_email", data)
}
// 发送邮件列表
export const send_email_listApi = () => {
    return post("/user/emails/sent_email_list")
}
// 获取联系人列表
export const getContactListApi = (data) => {
    return post("/user/information/contact_list",data)
}
// 查询联系人
export const searchContact_Api = (data) => {
    return post("/user/search/search_contacts", data)
}
// 获取联系人详情
export const getContactDetailApi = (data) => {
    return post("/user/information/contact_details", data)
}
// 接收邮件列表
export const receiveEmailListApi = (data) => {
    return post("/user/emails/receive_email_list",data)
}
//收件箱查询
export const receiveEmailSearchApi = (data) => {
    return post("/user/search/search_emails",data)
}
// 发送邮件列表
export const sentEmailListApi = (data) => {
    return post("/user/emails/sent_email_list",data)
}
// 接收邮件详情
export const receiveEmailDetailApi = (id) => {
    return post("/user/emails/receive_email_content", { id })
}
// 发送邮件详情
export const sentEmailDetailApi = (id) => {
    return post("/user/emails/sent_email_content", { id })
}
// 接收邮件删除
export const receiveEmailDeleteApi = (id) => {
    return post("/user/emails/receive_email_delete", { id })
}
// 发送邮件删除
export const sentEmailDeleteApi = (id) => {
    return post("/user/emails/sent_email_delete", { id })
}
// 查询收件箱
export const searchContactApi = (data) => {
    return post("/user/information/query_information", data)
}
// 首页信息
export const homePageApi = () => {
    return post("/user/information/query_information")
}
// 邮件已读
export const emailReadApi = (id) => {
    return post("/user/emails/read", { id })
}
// 获取用户
export const getUserApi = (data) => {
    return post("/admin/user/users",data)
}
// 删除用户
export const deleteUserApi = (data) => {
    return post("/admin/user/delete",data)
}